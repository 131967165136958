import React, { useState, useRef } from "react";

import {
  ContactFormContainer,
  EmailForm,
  InputField,
  MessageField,
  SendButton,
} from "./contact.styles";
import { send } from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [buttonText, setButtonText] = useState("Send Email");
  const [toSend, setToSend] = useState({
    from_name: "",
    to_name: "Bob Knox",
    message: "",
    reply_to: "",
    "g-recaptcha-response": "",
  });
  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);
  const recaptchaRef = useRef(null);

  const onSubmit = (e) => {
    e.preventDefault();
    if (!isRecaptchaVerified) return;
    setIsSubmitted(true);
    setButtonText("sending...");
    send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      toSend,
      process.env.REACT_APP_EMAILJS_USER_ID
    )
      .then((response) => {
        setButtonText("Thank you, your email has been sent.");
      })
      .catch((err) => {
        alert("email not sent, please try again");
      });
  };

  const onRecaptchaChange = (token) => {
    setToSend({ ...toSend, "g-recaptcha-response": token });
    setIsRecaptchaVerified(true);
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <>
      {" "}
      <ContactFormContainer>
        <EmailForm onSubmit={onSubmit} class="mui-form">
          <InputField
            type="text"
            name="from_name"
            required="true"
            placeholder="Your name*"
            value={toSend.from_name}
            onChange={handleChange}
          />
          <InputField
            type="text"
            name="reply_to"
            required="true"
            placeholder="Your email*"
            value={toSend.reply_to}
            onChange={handleChange}
          />
          <MessageField
            type="text"
            multiline={true}
            required="true"
            name="message"
            placeholder="Your message*"
            value={toSend.message}
            onChange={handleChange}
          />
          <ReCAPTCHA
            sitekey="6LdbI2slAAAAAJwT0ivLfuAz7UawYZiQ6U9gf6pd"
            ref={recaptchaRef}
            onChange={onRecaptchaChange}
          />
          <SendButton
            variant="outlined"
            color="primary"
            type="submit"
            disabled={isSubmitted || !isRecaptchaVerified}
          >
            {buttonText}
          </SendButton>{" "}
        </EmailForm>
      </ContactFormContainer>
    </>
  );
};

export default ContactForm;
