import React from "react";
import {
  NavBody,
  NavTitleContainer,
  NavTitle,
  NavLinksContainer,
  ContactButton,
  NavSubTitle,
} from "./navigation.styles";
import { Link, animateScroll as scroll } from "react-scroll";

const NavBar = () => {
  const handleClick = () => {
    scroll.scrollTo("#Contact", {
      duration: 500,
      smooth: true,
    });
  };

  return (
    <>
      <NavBody>
        <NavTitleContainer>
          <NavTitle variant="h1">Knox Swan & Dog</NavTitle>
          <NavSubTitle variant="h6">25350 West Scott Road, Barrington Illinois, 60010</NavSubTitle>
          <NavSubTitle variant="h6">847-875-3947</NavSubTitle>
        </NavTitleContainer>

        <NavLinksContainer>
          <ContactButton onClick={handleClick}>
            <Link to="Contact" spy={true} smooth={true} duration={500} offset={-70}>
              Contact Us
            </Link>
          </ContactButton>
        </NavLinksContainer>
      </NavBody>
    </>
  );
};

export default NavBar;
