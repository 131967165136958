import styled from "@emotion/styled";
import { Box, Card, Typography } from "@mui/material";
import { colors } from "../../infrastructure/theme/colors";
import { fontSizes } from "../../infrastructure/theme/fonts";
import { space } from "../../infrastructure/theme/spacing";
export const CardContainer = styled(Box)`
  margin-top: ${space[4]};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  height: fit-content;
  width: 100%;
`;

export const InfoCard = styled(Card)`
  margin: 30px;
  min-height: auto;
  color: ${colors.ui.tertiary};
  @media (max-width: 767px) {
    min-width: 100%;
  }
`;

export const ListHeading = styled(Typography)`
  font-weight: 600;
  font-size: ${fontSizes.h5};
`;

export const Disclaimer = styled(Typography)`
  font-weight: 200;
  font-size: 12px;
`;

export const ListSubHeading = styled(Typography)`
  font-weight: 600;
  font-size: ${fontSizes.h6};
`;
