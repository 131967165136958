import React from "react"
import { HeroBody, HeroSubText, HeroText, TextContainer } from "./hero.styles"

const Hero = () => {
  return (
    <>
      <HeroBody>
        <TextContainer>
          <HeroText>Knox Swan & Dog</HeroText>
          <HeroSubText>Ethical and Effective Canada Goose Control</HeroSubText>
        </TextContainer>
      </HeroBody>
    </>
  )
}

export default Hero
