import styled from "@emotion/styled";
import { colors } from "../../infrastructure/theme/colors";
import { fontSizes } from "../../infrastructure/theme/fonts";
import { space } from "../../infrastructure/theme/spacing";
import { Box, Typography } from "@mui/material";
import texture from "../../assets/images/dark-fish-skin.png";

export const InfoBody = styled(Box)`
  background: linear-gradient(rgba(57, 91, 100, 0.9), rgba(165, 201, 202, 0.9)), url(${texture});
  height: auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  box-shadow: 0px 1px 50px rgb(44, 51, 51);
  position: relative;
  z-index: 999;
`;

export const InfoBanner = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: 100%;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
`;
export const InfoHeading = styled(Typography)`
  font-size: ${fontSizes.h2};
  font-family: "Montserrat", sans-serif;
  text-align: center;
  color: ${colors.text.primary};
  font-weight: 700;
  margin-top: 100px;
  text-shadow: 1px 2px 2px #000;
  @media (max-width: 767px) {
    width: 100%;
    font-size: ${fontSizes.h3};
    margin: ${space[1]} ${space[2]};
  }
`;

export const InfoSubHeading = styled(Typography)`
  font-size: ${fontSizes.h5};
  font-family: "Montserrat", sans-serif;
  text-align: left;
  color: ${colors.text.primary};
  font-weight: 400;
  margin: ${space[3]} ${space[4]} ${space[5]};
  @media (max-width: 767px) {
    width: 100%;
    font-size: ${fontSizes.h6};
    margin: ${space[3]} ${space[4]} ${space[5]};
    text-align: center;
  }
`;

export const InfoBannerText = styled(Typography)`
  font-size: ${fontSizes.h5};
  width: 45%;
  text-align: left;
  color: ${colors.text.primary};
  font-weight: 100;
  margin: ${space[5]} ${space[2]} ${space[5]} ${space[0]};
  @media (max-width: 767px) {
    width: 100%;
    font-size: ${fontSizes.h6};
    text-align: center;
    margin: ${space[4]} ${space[0]};
  }
`;

export const Video = styled.iframe`
  border: none;
  margin: ${space[2]} ${space[0]} ${space[2]} ${space[1]};
  border-radius: 2px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%);
  background-color: #fff;
  padding: ${space[1]};
  @media (max-width: 767px) {
    width: 75%;
  }
`;
