import styled from "@emotion/styled";
import { colors } from "../../infrastructure/theme/colors";
import { fontSizes } from "../../infrastructure/theme/fonts";
import { space } from "../../infrastructure/theme/spacing";
import texture from "../../assets/images/dark-fish-skin.png";

import { Box, Typography } from "@mui/material";

export const FootContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  background: linear-gradient(rgba(57, 91, 100, 0.8), rgba(57, 91, 100, 1)), url(${texture});
`;

export const FootText = styled(Typography)`
  color: ${colors.text.primary};
  font-size: ${fontSizes.h5};
  padding: ${space[2]};
  font-family: "Montserrat", sans-serif;
`;
