import "./App.css";
import Header from "./components/navigation/navigation";
import Hero from "./components/hero/hero";
import Info from "./components/info/info";
import Contact from "./components/contact/contact";
import Footer from "./components/footer/footer";

function App() {
  return (
    <>
      <Header />
      <Hero />
      <Info />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
