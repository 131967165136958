import styled from "@emotion/styled";
import { colors } from "../../infrastructure/theme/colors";
import { fontSizes } from "../../infrastructure/theme/fonts";
import { sizes } from "../../infrastructure/theme/sizing";
import { Box, Popover, Typography } from "@mui/material";
import { GlobalButton } from "../global/global.components";

export const NavBody = styled(Box)`
  position: relative;
  background: ${(props) => colors.brand.muted};
  min-height: 100px;
  min-width: 100vw;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0px 2px 50px rgba(0 0 0);
  z-index: 999;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
  }
`;

export const NavTitleContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 30px 0 30px;
`;

export const NavTitle = styled(Typography)`
  font-family: "Montserrat", sans-serif;
  font-size: ${fontSizes.h4};
  font-weight: 300;
  color: ${colors.text.primary};
  @media (max-width: 767px) {
    font-size: ${fontSizes.h5};
    text-align: center;
  }
`;

export const NavSubTitle = styled(Typography)`
  font-family: "Montserrat", sans-serif;

  font-size: ${fontSizes.h6};
  font-weight: 100;
  color: ${colors.text.primary};
  @media (max-width: 767px) {
    text-align: center;
    margin: 5px;
  }
`;

export const NavLinksContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 30px 0 30px;
`;

export const ContactButton = styled(GlobalButton)`
  width: 180px;
  height: 60px;
  background-color: ${colors.ui.secondary};
  color: ${colors.text.secondary};
  border-radius: 15px;
  font-family: "Montserrat", sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  &:hover {
    transition: 0.75s ease;
    background-color: ${colors.ui.primary};
    color: ${colors.text.secondary};
    border-radius: 25px;
  }
`;
