export const colors = {
  brand: {
    primary: "#E7F6F2",
    secondary: "#A5C9CA",
    muted: "#395B64",
  },
  ui: {
    primary: "#E7F6F2",
    secondary: "#A5C9CA",
    tertiary: "#395B64",
    quaternary: "#2C3333",
    disabled: "#DDDDDD",
    error: "#F05454",
    success: "#138000",
  },
  bg: {
    primary: "#E7F6F2",
    secondary: "#A5C9CA",
  },
  text: {
    primary: "#E7F6F2",
    secondary: "#2C3333",
    disabled: "#9C9C9C",
    inverse: "#FFFFFF",
    error: "#F05454",
    success: "#138000",
  },
};
