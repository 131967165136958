import React, { useState } from "react";
import {
  CardContent,
  CardMedia,
  CardActions,
  Typography,
  Collapse,
  Button,
  Divider,
  List,
  ListItem,
} from "@mui/material";

import styled from "@emotion/styled";
import {
  CardContainer,
  Disclaimer,
  InfoCard,
  ListHeading,
  ListSubHeading,
} from "./cards.styles";
import { InfoHeading } from "../info/info.styles";
import collies from "../../assets/images/collies.jpg";
import swans from "../../assets/images/swanbg2.jpg";
import swans2 from "../../assets/images/swanbg.jpg";
import mute from "../../assets/images/Female_of_Mute_Swan_with_youngs.jpg";
import silver from "../../assets/images/silver-1.jpg";
import silverblk from "../../assets/images/silver-pen-4-months.jpg";
import silverblk2 from "../../assets/images/silver-hatched.jpg";
import nest from "../../assets/images/nesting_platform.jpg";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <Button {...other} />;
})(() => ({}));

const OpenMore = styled((props) => {
  const { expand, ...other } = props;
  return <Button {...other} />;
})(() => ({}));

const ExtendMore = styled((props) => {
  const { expand, ...other } = props;
  return <Button {...other} />;
})(() => ({}));

const Cards = () => {
  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const [extend, setExtend] = useState(false);

  const handleClick = () => {
    setExpanded(!expanded);
  };

  const handlePress = () => {
    setOpen(!open);
  };
  const handleExtend = () => {
    setExtend(!extend);
  };

  const DogCard = () => {
    return (
      <>
        <InfoHeading>Services we offer</InfoHeading>
        <InfoCard sx={{ width: "80%" }}>
          <CardMedia
            component='img'
            height='250'
            image={collies}
            alt='Border Collie'
          />
          <CardContent>
            <Typography gutterBottom variant='h5' component='div'>
              The Border Collie Program
            </Typography>
            <Typography variant='body2' color='text.secondary'>
              We offer comprehensive options with our team of working dogs to
              fit your goose control needs.
            </Typography>
          </CardContent>
          <CardActions>
            <OpenMore
              expand={open}
              onClick={handlePress}
              aria-expanded={open}
              variant='outlined'
              size='small'
              aria-label='show more'
            >
              {!open ? "Learn More" : "Close"}
            </OpenMore>
          </CardActions>
          <Collapse in={open} timeout={500} unmountOnExit>
            <Divider />

            <CardContent>
              <Typography paragraph>
                The Border Collie’s persistent herding technique and innate
                desire to work make them an outstanding and reliable breed for
                goose deterrent! Options for our Border Collie Programs are
                listed below.
              </Typography>
              <ListHeading paragraph>
                {" "}
                Border Collie Goose Control Packages{" "}
                <Disclaimer paragpraph>
                  Pricing subject to variation based on size of property
                </Disclaimer>
              </ListHeading>
              <Divider />
              <br />
              <Typography paragraph>
                We will perform Daily rounds to your property at various times
                of the day and night, seven days a week. Using the border
                collies to chase the geese on a regular basis will discourage
                the geese from using your property as a nesting site as they
                will now associate your property as an area where predators
                reside. Thus making your property an inhospitable territory for
                the geese.
              </Typography>
              <Divider />

              <ListHeading paragraph> Daily Goose Control</ListHeading>
              <Typography paragraph>
                We are available on a daily basis for your goose control needs
                with our Border Collies. <br />
                (48 hours minmum noticed is required)
              </Typography>
              <Divider />

              <ListHeading paragraph> Monthly Goose Control</ListHeading>
              <Typography paragraph>
                Only need our services for a month? Then our monthly package is
                for you.
              </Typography>
              <Divider />

              <ListHeading paragraph> Seasonal Goose Control</ListHeading>
              <Typography paragraph>
                Comprehensive goose control with our border collies from March 1
                <sup>st</sup> - November 30<sup>th</sup>
              </Typography>
              <Divider />

              <ListHeading paragraph> Yearly Goose Control</ListHeading>
              <Typography paragraph>
                Our Most popular option.
                <br />
                Garuntee that geese never see your property as a possible
                nesting site with our year round Border Collie Program
              </Typography>
              <br />
              <Divider />

              <ListHeading paragraph>
                {" "}
                Why do we use Border Collies specifically?
              </ListHeading>
              <br />

              <Typography paragraph>
                The Border Collie’s persistent herding technique and desire to
                work make them an outstanding goose chaser! The Border Collie
                uses its wolf-like glance called “eyeing”, to mesmerize their
                prey into flight. The geese actually consider the Border Collie
                to be predators and therefore they think it is unsafe for them
                to remain in the area. The geese are not safe in the water
                either. Our dogs love to swim and are a great asset in quickly
                clearing the geese from your site. By chasing the geese from the
                grass and landscaped areas as well as the waterways, we have a
                very effective program for getting rid of those pesky geese.
              </Typography>
              <Typography paragraph>
                Our dogs are trained to obey their handler and will perform
                their duties without hesitation.
              </Typography>
              <Divider />
            </CardContent>
          </Collapse>
        </InfoCard>
      </>
    );
  };
  const SwanCard = () => {
    return (
      <>
        <InfoCard sx={{ width: "80%" }}>
          <CardMedia
            sx={{ objectPosition: "100% 30%" }}
            component='img'
            height='250'
            image={swans}
            alt='Mute Swans'
          />
          <CardContent>
            <Typography gutterBottom variant='h5' component='div'>
              Swan Programs
            </Typography>
            <Typography variant='body2' color='text.secondary'>
              Knox Swan & Dog LLC uses Mute Swans to clear Canada Geese from
              your property.
            </Typography>
          </CardContent>
          <CardActions>
            <ExpandMore
              expand={expanded}
              onClick={handleClick}
              aria-expanded={expanded}
              variant='outlined'
              size='small'
              aria-label='show more'
            >
              {!expanded ? "Learn More" : "Close"}
            </ExpandMore>
          </CardActions>
          <Collapse in={expanded} timeout='auto' unmountOnExit>
            <Divider />
            <CardContent>
              <ListHeading paragraph>
                The Comprehensive Knox Swan Program{" "}
              </ListHeading>
              <Typography paragraph>
                Mute swans have become a popular way to enhance the beauty of
                your landscape, and are a valuable asset in maintaining an
                ecologically balanced pond. Mute swans love to eat algae. A pair
                of breeding Mute swans can keep a one acre pond free from algae,
                keeping the pond clean and inviting.
                <br />
                <br />
                Aesthetically, the swan can not be rivaled for their beauty on
                your pond or lake. Watching a pair of Mute swans nest, hatch
                their babies and raise them to adulthood is a process that is
                enjoyed by all.
                <br />
                <br /> Knox Swan and Dog LLC has over 25 years of experience in
                breeding and raising swans. We sell and rent Mute swans. Call{" "}
                <strong>847-875-3947</strong> for availability.
                <br />
                <br />
                All swans have different personalities and temperaments. We will
                select the birds that will work best on your property, ensuring
                a clean and inviting environment.
              </Typography>
              <Divider />
              <ListHeading paragraph>Our swan program includes:</ListHeading>
              <Typography paragraph>
                <List>
                  <ListItem>One breeding age pair of Mute Swans</ListItem>
                  <ListItem>One nesting area</ListItem>
                  <ListItem>One swan feeder</ListItem>
                  <ListItem>Maintenance free feeding for the season</ListItem>
                </List>
              </Typography>
              <Divider />
              <ListHeading paragraph>
                The Importance of Nesting Swans
              </ListHeading>
              <Typography paragraph>
                When the swans are nesting and raising their young (called
                Cygnets), they are very defensive of their territory. Therefore
                a nesting pair of swan are an extremely effective method of
                Canada goose repulsion. The likleyhood of a swan chasing away
                geese decreases when the swan is not raisng Cygnets.
                <br />
                <br />
                Swans are more effective during their second season on a
                property as they recognize the territory as their own home and
                will defend it from geese more aggressively.
              </Typography>
              <Divider />
              <ListHeading>
                Acclimating your Swans to their new home
              </ListHeading>
              <br />
              <ListSubHeading>Pens</ListSubHeading>
              <br />
              <Typography paragraph>
                To acclimate the swans to their new home, a temporary pen should
                be set up. It needs to be a minimum 5 feet high and the fence
                needs to have small enough mesh so the swans cannot stick their
                heads through and injure themselves. Ideally, the pen should be
                10 feet square and if possible half in the water and half on
                land. If not, you can use a bucket to keep the swans watered.
              </Typography>
              <Divider />
              <br />
              <ListSubHeading>Feeding</ListSubHeading>
              <br />
              <Typography paragraph>
                It is imperitive to provide a feeder in your swan pen. The swans
                need to be kept in the pen for 7 to 10 days until they settle
                down and realize that this is their new home and they know where
                their feeder is.
              </Typography>
              <Divider />
              <br />
              <ListSubHeading>Nesting Materials</ListSubHeading>
              <br />
              <Typography paragraph>
                If no natural nesting area is available, three straw bales can
                be placed near the feeder in a square formation with the up hill
                side open. Put wood chips or small branches, cattails or long
                grasses in the center to encourage them to nest where you want
                them.
              </Typography>
              <Divider />
              <br />
              <ListSubHeading>Initial Rlease</ListSubHeading>
              <br />
              <Typography paragraph>
                When releasing the swans, open one side of the pen and allow the
                swans to walk out at their leisure. Once they are out you can
                take down rest of the pen. The swans are permanently pinioned so
                they are flightless and only leave an area if they are not being
                fed enough
              </Typography>
              <Divider />
              <br />
              <ListSubHeading>Important Notes</ListSubHeading>
              <br />
              <Typography paragraph>
                It is very important that you keep feed in the feeders at all
                times. When the swans are first put into the pen fill the feeder
                so the feed is slightly above the hole and watch them to ensure
                they are eating out of it. Once released let the feed level go
                down so the geese do not get a free meal. Always keep feed in
                the feeder so the swans remain where you want them.
              </Typography>
              <Divider />
              <br />
            </CardContent>
          </Collapse>
        </InfoCard>
      </>
    );
  };
  const SaleCard = () => {
    return (
      <>
        <InfoCard sx={{ width: "80%" }}>
          <CardMedia
            component='img'
            height='250'
            image={swans2}
            alt='Mute Swan'
          />
          <CardContent>
            <Typography gutterBottom variant='h5' component='div'>
              Swans & More For Sale
            </Typography>
            <Typography variant='body2' color='text.secondary'>
              We sell swans & nesting platforms directly to you!
            </Typography>
          </CardContent>
          <CardActions>
            <ExpandMore
              expand={extend}
              onClick={handleExtend}
              aria-expanded={extend}
              variant='outlined'
              size='small'
              aria-label='show more'
            >
              {!extend ? "Learn More" : "Close"}
            </ExpandMore>
          </CardActions>
          <Collapse in={extend} timeout='auto' unmountOnExit>
            <Divider />
            <CardContent>
              <ListHeading>Swan Breeds We Sell</ListHeading>
              <Disclaimer>
                We ship our swans safely and humanely nationwide.
                <br />
                <br />
                SAME DAY DELIVERY: We use American Airlines for same-day service
                <br />
                OVERNIGHT DELIVERY: We also use American Airlines to ship swans
                overnight
                <br />
                Shipping costs vary depending which method you choose and where
                in the United States you are located. Call today for shipping
                rates on our swans.
              </Disclaimer>
              <Divider />
              <ListSubHeading>Mute Swans</ListSubHeading>
              <br />
              <CardMedia
                sx={{ borderRadius: 2 }}
                component='img'
                height='auto'
                image={mute}
                alt='Mute Swan'
              />

              <br />
              <Divider />
              <Divider />

              <ListHeading>Swan Nesting Platforms $1,800</ListHeading>
              <Disclaimer>
                {" "}
                Not available for shipping, must be picked up from our location.
                <br />
                Length 60″, Width 46″, Height 13″, Weight 55 lbs.
                <br />
              </Disclaimer>
              <br />
              <Divider />
              <ListSubHeading>
                A floating, secure nesting platform
              </ListSubHeading>
              <Typography paragraph>
                The nesting platform floats so you can anchor it within your
                pond to protect the nesting swans from predators.{" "}
                <CardMedia
                  sx={{ borderRadius: 2, width: "45%", margin: "0" }}
                  component='img'
                  height='auto'
                  image={nest}
                  alt='nesting platform'
                />
              </Typography>
              <br />
              <ListSubHeading>Flood Protection</ListSubHeading>
              <Typography paragraph>
                The nesting platform keeps the nesting site from flooding during
                the rainy season. This will aid in saving the swans eggs.
              </Typography>
              <br />
              <ListSubHeading>Accommodating Ramp</ListSubHeading>
              <Typography paragraph>
                The nesting platform’s ramp is designed to accomodate the swan’s
                young. Even day-old cygnets (swan babies) are able to easily
                retreat to their mother’s protective nest.
              </Typography>
              <br />
              <ListSubHeading>A Beautiful View</ListSubHeading>
              <Typography paragraph>
                You will enjoy watching your swans for hours while they hatch
                and raise their cygnets on this nesting platform specifically
                designed for swans. From a safe distance, your swans will feel
                secure, and you will have the opportunity to watch cygnets grow
                into beautiful adults.
              </Typography>

              <Divider />

              <Divider />
              <br />
              <ListHeading>Payment</ListHeading>
              <Typography paragraph>
                We accept money Orders, Visa and Matercard
                <br />
                <br />
                CREDIT CARD SURCHARGE: On swan purchases there is a $25.00
                surcharge if paying by credit card.
                <br />
                <br />
                Call for availability on all breeds of swans and to discuss and
                reserve what you need.
              </Typography>
              <Divider />
            </CardContent>
          </Collapse>
        </InfoCard>
      </>
    );
  };

  return (
    <>
      <CardContainer>
        <DogCard />
        <SwanCard />
        <SaleCard />
      </CardContainer>
    </>
  );
};

export default Cards;
