import styled from "@emotion/styled";
import { colors } from "../../infrastructure/theme/colors";
import { fontSizes } from "../../infrastructure/theme/fonts";
import { space } from "../../infrastructure/theme/spacing";
import { Box, Typography } from "@mui/material";
import img from "../../assets/images/swanbg3.jpg";

export const HeroBody = styled(Box)`
  position: relative;
  height: 600px;

  background: linear-gradient(
      rgba(152, 168, 248, 0.1),
      rgba(152, 168, 248, 0.25),
      rgba(152, 168, 248, 0.55)
    ),
    url(${img});
  background-repeat: no-repeat;
  background-position: 50%, 10%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0;
  box-shadow: 0px 1px 50px rgb(44, 51, 51);
  z-index: 998;
  @media (max-width: 767px) {
    background-position: 70% 20%;
    align-items: center;
  }
`;
export const TextContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  width: fit-content;
  margin: ${space[4]};
  position: relative;
  bottom: ${space[5]};
  justify-content: center;
  @media (max-width: 767px) {
    margin: ${space[3]};
  }
`;

export const HeroText = styled(Typography)`
  font-family: "Yellowtail", cursive;
  font-size: ${fontSizes.h1};
  font-weight: 300;
  color: ${colors.text.primary};
  text-shadow: 2px 2px 3px #000;
  @media (max-width: 767px) {
    font-size: ${fontSizes.h2};
    text-align: center;
  }
`;
export const HeroSubText = styled(Typography)`
  font-size: ${fontSizes.h5};
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin-top: ${space[2]};
  color: ${() => colors.text.primary};
  text-shadow: 2px 2px 3px #000;

  @media (max-width: 767px) {
    font-size: ${fontSizes.h6};
    text-align: center;
  }
`;
