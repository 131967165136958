import React from "react";
import Cards from "../cards/cards";
import {
  InfoBanner,
  InfoBannerText,
  InfoBody,
  InfoHeading,
  InfoSubHeading,
  Video,
} from "./info.styles";
export const Info = () => {
  return (
    <>
      <InfoBody>
        <InfoHeading>Got Geese?</InfoHeading>
        <InfoSubHeading>
          Knox Swan & Dog has specialized in Canada Goose control in the
          Chicagoland area since 1990! We use Border Collies and Mute Swans,
          individually or matched up in different packages, to “chase away”
          Canada geese, keeping your lawns and landscapes clean and inviting.
        </InfoSubHeading>
        <InfoBanner>
          <InfoBannerText>
            At Knox Swan & Dog, we offer a multitude of effective and ethical
            solutions to rid your property of the ever encroaching, and notably
            bold Canada Goose. From swan sales and rentals, to our Border Collie
            Program, we will work together to find an option that fits your
            budget and needs.
            <p>
              Call <strong>847-875-3947</strong> for a free estimate today.
            </p>{" "}
          </InfoBannerText>
          <Video
            width='560'
            height='315'
            src='https://www.youtube.com/embed/GTadm8eL1gY'
            title='YouTube video player'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowfullscreen
          />
        </InfoBanner>

        <Cards />
      </InfoBody>
    </>
  );
};

export default Info;
