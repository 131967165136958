import React from "react";
import { FootContainer, FootText } from "./footer.styles";

const Footer = () => {
  const date = new Date();

  const year = date.getFullYear();

  return (
    <FootContainer>
      <FootText> © Knox Swan & Dog {year} </FootText>
    </FootContainer>
  );
};
export default Footer;
