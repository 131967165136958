import React from "react";
import ContactForm from "./contact.form";
import {
  ContactBody,
  PrimarySection,
  PrimaryContainer,
  Title,
  SecondarySection,
  SecondaryTitle,
} from "./contact.styles";

const Contact = () => {
  return (
    <>
      <ContactBody id="Contact">
        <Title>Contact us</Title>
        <PrimarySection>
          <PrimaryContainer>
            <SecondaryTitle>
              If you have any questions about our services or offerings please send us an email
            </SecondaryTitle>
          </PrimaryContainer>
          <SecondarySection>
            <ContactForm />
          </SecondarySection>
        </PrimarySection>
      </ContactBody>
    </>
  );
};

export default Contact;
