import styled from "@emotion/styled";
import { Button, Input, Box, Typography } from "@mui/material";
import { colors } from "../../infrastructure/theme/colors";
import { space } from "../../infrastructure/theme/spacing";
import { fontSizes } from "../../infrastructure/theme/fonts";
import img from "../../assets/images/boats-on-lake.jpg";

export const ContactBody = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: fit-content;
  background: linear-gradient(
      rgba(57, 91, 100, 0.45),
      rgba(57, 91, 100, 0.75),
      rgba(57, 91, 100, 0.75),
      rgba(57, 91, 100, 0.95)
    ),
    url(${img});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const ContactFormContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: ${colors.brand.primary};
  border-radius: 5px;
  margin: ${space[0]} auto ${space[2]};
  width: 90%;
  height: fit-content;
  padding: ${space[2]};
  box-shadow: 0px 2px 8px rgba(0 0 0);
  @media screen and (max-width: 650px) {
    width: 90%;
    margin: ${space[2]} auto;
    position: relative;
    left: 5px;
  }
`;

export const EmailForm = styled.form`
  justify-content: center;
`;

export const InputField = styled(Input)`
  width: 100%;
  padding: 10px;
`;
export const MessageField = styled(InputField)`
  min-height: 200px;
  margin-bottom: 20px;
  flex-wrap: wrap;
`;
export const SendButton = styled(Button)`
  width: 100%;
`;
export const PrimarySection = styled(Box)`
  min-height: fit-content;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${space[0]} ${space[0]} ${space[4]};
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;
export const SecondarySection = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  min-height: fit-content;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
`;
export const PrimaryContainer = styled(Box)`
  height: 100%;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0%;
`;
export const SecondaryContainer = styled(Box)`
  height: fit-content;
  width: 100vw;
  display: flex;
  padding: 3px;
  flex-wrap: wrap;
  justify-content: center;
  justify-content: space-around;
`;

export const Title = styled(Typography)`
  text-align: center;
  position: relative;
  bottom: 20%;
  padding: ${space[2]} ${space[0]} ${space[3]};
  font-size: 3.5rem;
  font-weight: 800;
  font-family: "Montserrat", sans-serif;
  color: ${colors.text.primary};
  text-shadow: ${colors.text.secondary} 1px 1px 1px;

  text-align: center;
`;
export const Subtitle = styled(Typography)`
  height: 30px;
  font-size: 1.5rem;
  outline-width: 5px;
  outline-style: hidden;
  padding: 5px;
  border-radius: 8px;
  background-color: ${colors.brand.primary};
  text-align: center;
  color: ${colors.text.primary};
  text-shadow: #ecb365 1px 1px 1px;
`;

export const SecondaryTitle = styled(Typography)`
  font-size: ${fontSizes};
  margin: 20px;
  padding: 7px;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  font-weight: 900;
  color: ${colors.text.primary};
  text-shadow: ${colors.text.secondary} 1px 1px 1px;
  @media (max-width: 767px) {
    font-size: ${fontSizes.h6};
  }
`;
